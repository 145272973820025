@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  font-family: "Inter", sans-serif;
}
body.modal-open {
  overflow-y: hidden;
  overflow-x: hidden;
  position: fixed;
  width: 100%;
}

@media only screen and (max-width: 500px) {
  .full-screen {
    height: calc(var(--vh, 1vh) * 100);
  }
}

.quantity_container {
  margin-top: 5px;
  width: 100%;
  height: 40px;
  background: #f3f3f3;
  border-radius: 5px;
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
}

.quantity_container input {
  -webkit-appearance: none;
  appearance: none;
  background: transparent;
  height: 100%;
  width: 100%;
  font-size: 24px;
  font-weight: 700;
  line-height: 40px;
  text-align: center;
  border: none;
  outline: none;
}

/* Chrome, Safari, Edge, Opera */
.quantity_container input::-webkit-outer-spin-button,
.quantity_container input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
.quantity_container input[type="number"] {
  -moz-appearance: textfield;
}

.split-btn {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-family: Roboto, sans-serif, Arial;
  letter-spacing: 0.9px;
  background-color: rgba(255, 255, 255, 1);
  border: 1px solid rgba(209, 209, 209, 1);
  width: calc(100% - 30px);
  margin: 15px auto;
  padding: 16px 15px;
}
.split-btn:focus {
  border: 1px solid rgba(209, 209, 209, 1);
}

#qrcode-container {
  transition: all 0.4s ease-in-out;
  height: 0;
  overflow: hidden;
}
.order-btn-container {
  font-size: 16px;
  text-align: center;
  line-height: 20px;
  letter-spacing: 0px;
  color: rgba(56, 56, 56, 1);
  border: 1px solid rgba(209, 209, 209, 1);
  box-shadow: 1px 1px 3px rgba(176, 176, 176, 0.5);
}

.order-btn {
  appearance: none;
  background: #fff;
  width: 100%;
  padding: 12px;
  border: 0;
  cursor: pointer;
  color: #000;
}
.order-btn:focus {
  border: 0;
}

.tips {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  padding: 10px 15px;
  margin-bottom: 20px;
}
.tip-content {
  text-align: center;
  margin: 0 15px;
  font-size: 15px;
  max-width: 60px;
  overflow: hidden;
  height: 70px;
}
.tip-btn {
  font-size: 15px;
  appearance: none;
  background: #fff;
  border: 0;
  width: 44px;
  height: 44px;
  border-radius: 50%;
  border: 1px solid #000;
  display: block;
  text-align: center;
  padding: 0;
  cursor: pointer;
  color: #000;
}
.tip-btn.selected {
  border: 3px solid #22c55e;
}

.pay-now-btn {
  display: block;
  font-size: 15px;
  appearance: none;
  background: #fff;
  border-radius: 4px;
  padding: 6px;
  margin: 15px auto;
  border: 1px solid rgba(209, 209, 209, 1);
  transition: all 0.5s ease-in-out;
  cursor: pointer;
}
.pay-now-btn {
  margin-top: 5px;
  color: #fff;
  background: rgba(84, 194, 66, 1);
  width: calc(100% - 30px);
}

.error-box::after {
  content: " ";
  position: absolute;
  top: 50%;
  margin: 0px;
  right: -18px;
  transform: translateY(-50%);
  width: 0;
  height: 0;
  border-style: solid;
  border-top: 11.5px solid transparent;
  border-bottom: 13px solid transparent;
  border-left: 18px solid red;
  border-right: 0;
}
